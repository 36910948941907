import SvgIcon, { type SvgIconProps } from '~/components/SvgIcon'

const ArrowRight = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 28 26" width="28" height="26" {...props}>
      <path d="M27.5 14.125L16.5 24.625C15.875 25.1875 14.9375 25.1875 14.375 24.5625C13.8125 23.9375 13.8125 23 14.4375 22.4375L22.75 14.5H1.5C0.625 14.5 0 13.875 0 13C0 12.1875 0.625 11.5 1.5 11.5H22.75L14.4375 3.625C13.8125 3.0625 13.8125 2.0625 14.375 1.5C14.9375 0.875 15.9375 0.875 16.5 1.4375L27.5 11.9375C27.8125 12.25 28 12.625 28 13C28 13.4375 27.8125 13.8125 27.5 14.125Z" />
    </SvgIcon>
  )
}

export default ArrowRight
